

import request from '@/libs/request';

/*
 * 登录
 * */
export function AccountLogin(data) {
  return request({
    url: '/login',
    method: 'post',
    data,
  });
}


export function autoLogin(data) {
  return request({
    url: '/autoLogin',
    method: 'post',
    data,
  });
}




/**
 * 退出登陆
 * @constructor
 */
export function AccountLogout() {
  return request({
    url: '/auth/admin/logout',
    method: 'get',
  });
}


export function reloadSetting() {
  return request({
    url: `auth/admin/reload`,
    method: 'get',
  });
}



/**
 * 获取轮播图和logo
 */
export function loginInfoApi() {
  return request({
    url: '/login/info',
    method: 'get',
  });
}

/**
 * 获取菜单数据
 */
export function menusApi() {
  return request({
    url: '/menus',
    method: 'get',
  });
}

/**
 * 搜索菜单数据
 */
export function menusListApi() {
  return request({
    url: '/menusList',
    method: 'get',
  });
}

export function AccountRegister() {}




/**
 * 获取扫码登录凭证
 * @constructor
 */
export function getSanCodeKey() {
  return request({
    url: `/login/scan`,
    method: 'get',
  });
}

/**
 * 扫码登录情况
 */
export function scanStatus(key, params) {
  return request({
    url: 'login/scan/' + key,
    method: 'get',
    params,
  });
}

