import { getCookies, removeCookies ,setCookies} from '@/libs/util';
export default {
  namespaced: true,
  state: {
    adminInfo: null,
	roleInfo:null,
	//teamInfo:null,
	//teams:[],
	companyInfo:null,
	uniqueAuth: [],
	groups:[],
	currentGroupCode:'',
	wechats:[],
	version:'',
  },
  mutations: {
    adminInfo(state, adminInfo) {
      state.adminInfo = adminInfo;
    },
	roleInfo(state, roleInfo) {
	  state.roleInfo = roleInfo;
	},
	// teamInfo(state, teamInfo) {
	//   state.teamInfo = teamInfo;
	// },
	// teams(state, teams) {
	//   state.teams = teams;
	// },
	companyInfo(state, companyInfo) {
	  state.companyInfo = companyInfo;
	},
	uniqueAuth(state, uniqueAuth) {
	  state.uniqueAuth = uniqueAuth;
	},
	
	groups(state, groups) {
	  state.groups = groups;
	},
	currentGroupCode(state, currentGroupCode) {
	  state.currentGroupCode = currentGroupCode;
	  setCookies('currentGroupCode',currentGroupCode)
	  
	},
	wechats(state, wechats) {
	  state.wechats = wechats;
	},
	version(state, version) {
	  state.version = version;
	},
  },
  actions: {
    
  },
};
