<template>
	
	<div>
		<el-drawer
		    :visible.sync="drawer"
			:size="isMobile ? '100%' : 500"
			:class="isMobile ? 'padding0' : ''"
		  >
		    <el-descriptions title="基本信息"  :column="2" size="large" class="nickname">
				
		        <el-descriptions-item label="昵称" span="2" >
					{{getAdminInfos.real_name}}
					<el-avatar
						:src="getAdminInfos.head_pic"
						:size="80"
						class="avatar"
					/>
				</el-descriptions-item>
				
				<el-descriptions-item label="登录账号" span="2" >{{getAdminInfos.account}}</el-descriptions-item>
				<el-descriptions-item label="职位" span="2" >{{roleInfo.roleName}}</el-descriptions-item>
		        
		    </el-descriptions>
			
			<div class="operates acea-row row-middle row-right">
				<a @click="loadSetting"><i class="el-icon-s-help"></i> 重载配置</a>
				<el-divider direction="vertical"></el-divider>
				<a @click="updateInfo"><i class="el-icon-edit"></i> 修改资料</a>
				<el-divider direction="vertical"></el-divider>
				<a @click="updatePassword"><i class="el-icon-lock"></i> 修改密码</a>
				<el-divider direction="vertical"></el-divider>
				<a @click="logout"><i class="el-icon-switch-button"></i> 退出登录</a>
				
		
			</div>
			
			<el-descriptions title="主体"  :column="2" size="large" class="nickname mt25">
				
			    
				
				<el-descriptions-item label="主体名称" span="2" >{{companyInfo.name}}</el-descriptions-item>
			    
			</el-descriptions>
			
			
			
		</el-drawer>
		<update-admin-form ref="updateAdminForm"></update-admin-form>
		<update-password-form ref="updatePasswordForm"></update-password-form>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import updateAdminForm from './auth/updateAdminForm';
import updatePasswordForm from './auth/updatePasswordForm';
import { AccountLogout , reloadSetting } from '@/api/account';
export default {
  name: 'adminInfos',
  props:{
  },
  
  components: {
	updateAdminForm,
	updatePasswordForm
  },
  data() {
    return {
		drawer:false
    };
  },
  computed: {
	...mapState('media', ['isMobile']),
	// 获取登录信息
	getAdminInfos() {
	  return this.$store.state.login.adminInfo;
	},
	
	companyInfo() {
	  return this.$store.state.login.companyInfo;
	},
	roleInfo() {
	  return this.$store.state.login.roleInfo;
	},
	teamInfo() {
	  return this.$store.state.login.teamInfo || {};
	},
  },
  watch: {
	
  },
  created() {
	  
	  
  },
  methods: {
	
	updateInfo()
	{
		this.$refs.updateAdminForm.modals = true;
		this.$refs.updateAdminForm.init(this.getAdminInfos)
	},
	updatePassword()
	{
		this.$refs.updatePasswordForm.modals = true;
	},
	loadSetting()
	{
		let that = this;
		const loading = this.$loading({
		  lock: true,
		  text: '正在重载配置...',
		  spinner: 'el-icon-loading',
		  background: 'rgba(0, 0, 0, 0.7)'
		});
		reloadSetting().then((res) => {
		  let data = res.data;
		  this.$store.commit('login/uniqueAuth', data.unique_auth);
		  this.$store.commit('menus/getmenusNav', data.menus);
		  this.$store.commit('login/adminInfo', data.user_info);
		  this.$store.commit('login/roleInfo', data.role);
		  this.$store.commit('login/companyInfo', data.company_info);
		  this.$store.commit('login/version', data.version);
		  this.$store.commit('login/groups', data.groups);
		  this.$store.commit('login/wechats', data.wechats);
		  this.$store.commit('cfg/configs', data.configs);
		  
		  setTimeout(() => {
		    loading.close();
			that.$router.go(0);
		  }, 2000);
		  
		}).catch((res) => {
          this.$message.error(res.msg || '加载失败');
		  loading.close();
        });
	},
	logout()
	{
		setTimeout(() => {
		  this.$msgbox({
		    closeOnClickModal: false,
		    closeOnPressEscape: false,
		    showCancelButton: true,
		    confirmButtonText: '确定',
		    cancelButtonText: '取消',
			title:'提示',
			message:'此操作将退出登录, 是否继续?',
		    beforeClose: (action, instance, done) => {
		      if (action === 'confirm') {
		        instance.confirmButtonLoading = true;
		        instance.confirmButtonText = '退出中';
		        AccountLogout().then((res) => {
		          done();
		          this.$message.success('您已成功退出');
		          //this.$store.commit('clearAll');
		          removeCookies('token');
		          removeCookies('expires_time');
		          removeCookies('uuid');
		          setTimeout(() => {
		            this.$router.replace({ name: 'login' });
		            instance.confirmButtonLoading = false;
		          }, 1500);
		        });
		      } else {
		        done();
		      }
		    },
		  })
		    .then(() => {
		      // 清除缓存/token等
		      Session.clear();
		      // 使用 reload 时，不需要调用 resetRoute() 重置路由
		      window.location.reload();
		    })
		    .catch(() => {});
		}, 150);
	}
	
  },
};
</script>

<style scoped styles="scss">
	
.nickname{
	position: relative;
	padding: 0 15px;
	
}	
	
.avatar{
	position: absolute;
	right: 60px;
	top:0;
	
}

.operates{
	padding: 15px 15px 0;
	
	i{
	}
}

::v-deep.padding0 .el-drawer__body{
	padding: 20px 0!important;
}
</style>