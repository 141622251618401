

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'user_';

export default {
  path: routePre + '/user',
  name: 'user',
  header: 'user',
  redirect: {
    name: `${pre}index`,
  },
  component: LayoutMain,
  children: [
	
	
	{
	  path: 'index',
	  name: `${pre}index`,
	  meta: {
	    auth: ['user'],
	    title: '学员',
	  },
	  component: () => import('@/pages/user/index'),
	},
	
	{
	  path: 'create',
	  name: `${pre}create`,
	  meta: {
	    auth: ['user'],
	    title: '添加学员',
	  },
	  component: () => import('@/pages/user/create'),
	},
  ],
};
