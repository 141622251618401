
<template>
	
	
	  
	  
    <div class="layout layout-container" >
        <el-container>
            <el-header class="acea-row row-between-wrapper layout-navbars-breadcrumb-index" height="66px">
                <!-- <Menu mode="horizontal" theme="dark" active-name="1">
                    
                </Menu> -->
				
				<div class="acea-row">
					<div class="layout-logo">
						<!-- <el-image class="logo" :src="logo" fit="scale-down" /> -->
					</div>
					<transverse-aside :columnsAsideList="columnsAsideList" :activePath="currentHeaderPath" v-if="clientWidth > 1000"></transverse-aside>
					<mobile-menus :columnsAsideList="columnsAsideList" v-else></mobile-menus>
				</div>
				
				<user></user>
				
            </el-header>
            <el-container>
               
				<Asides :menuList="childMenuList" :activePath="currentPath" :openNames="childOpenNames" v-if="clientWidth > 1000" class="layout-aside layout-aside-width-default"/>
                
				<el-main class="layout-main">
					<el-scrollbar
					  class="layout-scrollbar"
					  ref="layoutScrollbarRef"
					  :style="{ minHeight: `calc(100vh - ${headerHeight}` }"
					>
					  <div style="height: 100%;display: flex;flex-direction: column;justify-content: space-between;">
						  <transition :name="setTransitionName" mode="out-in">
						  	<router-view :key="refreshRouterViewKey" />
						  </transition>
						  <Footers></Footers>
					  </div>
					</el-scrollbar>
					
				</el-main>
            </el-container>
        </el-container>
		
		
		
    </div>
</template>
<script>
import transverseAside from '@/layout/component/transverseAside.vue';
import mobileMenus from '@/layout/component/mobileMenus.vue';
import Asides from '@/layout/component/aside.vue';
import user from '@/layout/component/user.vue';
import Footers from '@/layout/component/footer.vue';
import { getSiderSubmenu, getHeaderName } from '@/libs/system';

// import {
//   ElContainer,
//   ElHeader,
//   ElMain,
//   ElFooter,
// } from 'element-plus'


export default {
  name: 'layout',
  components: {
    transverseAside,
	Asides,
	mobileMenus,
	// ElContainer,
	// ElHeader,
	// ElMain,
	// ElFooter,
	user,
	Footers
  },
  data() {
    return {
		columnsAsideList:[],
		
		currentHeaderPath:'',//当前顶部路径
		
		
		currentPath:'',//当前路径
		childMenuList:[],
		childOpenNames:[],
		
		headerHeight: '66px',
		
		refreshRouterViewKey:null,
		
		clientWidth: '',
    };
  },
  computed: {
	// 设置主界面切换动画
	setTransitionName() {
	  return this.$store.state.themeConfig.themeConfig.animation;
	},
	logo() {
	  return this.$store.state.login.companyInfo.logo;
	},
  },
  watch: {
	// 监听路由的变化
	$route: {
	  handler(to) {
		  this.setFilterRoutes()
	  },
	  deep: true,
	},
	
	"$store.state.menus.menusName":{
		handler:function(newVal,oldVal){
			this.setFilterRoutes()
		}
	}
  },
  created() {
	this.initMenuFixed(document.body.clientWidth);  
	  
	  
	this.setFilterRoutes();
	
	this.bus.$on('onTagsViewRefreshRouterView', (path) => {
	  this.refreshRouterViewKey = null;
	  setTimeout(() => {
		  this.refreshRouterViewKey = this.$route.path;	
	  }, 0)
	  
	});
	
	
	
  },
  methods: {
	
	setFilterRoutes() {
	  
	  this.columnsAsideList = this.$store.state.menus.menusName;
	  this.currentHeaderPath = getHeaderName(this.$route, this.columnsAsideList)
	  
	  const resData = this.setSendChildren(this.currentHeaderPath);
	  
	  
	  
	  if (!resData.children || this.$route.meta.hiddenMenu == true) {
		this.childMenuList = [];
		this.$set(this,'currentPath','')
		this.childOpenNames = [];
	    this.$store.state.themeConfig.themeConfig.isCollapse = true;
	    return false;
	  }
	  
	  
	  this.childMenuList = resData.children || [];
	  let childData = getSiderSubmenu(this.$route,this.childMenuList)
      this.childOpenNames = childData.openNames;
      this.$set(this,'currentPath',childData.path)
	  this.$store.state.themeConfig.themeConfig.isCollapse = false;
	  
	  
	},  
	
	// 传送当前子级数据到菜单中
	setSendChildren(path) {
	  let currentData = {};
	  this.columnsAsideList.map((v, k) => {
	    if (v.path === path) {
	      v['k'] = k;
	      currentData['item'] = [{ ...v }];
	      if (v.children) currentData['children'] = v.children;
	    }
	  });
	  return currentData;
	},
	
	// 设置菜单导航是否固定（移动端）
	initMenuFixed(clientWidth) {
	  this.clientWidth = clientWidth;
	  //this.$emit('routesListChange');
	},
  },
};
</script>

<style scoped lang="scss">


.el-container{
	height: calc(100vh - 66px);
}

.layout-navbars-breadcrumb-index {
  height: 66px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  background: var(--prev-bg-topBar);
  border-bottom: 1px solid var(--prev-border-color-lighter);
  padding: 0 !important;
}


.layout-logo{
	width:180px;
	height:66px;
	margin-right: 10px;
	.logo{
		width:100%;
		height:100%;
	}
}

::v-deep .layout-scrollbar{
	.el-scrollbar__view{
		height:100%;
	}
}

</style>