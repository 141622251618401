

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'order_';

export default {
  path: routePre + '/order',
  name: 'order',
  header: 'order',
  redirect: {
    name: `${pre}index`,
  },
  component: LayoutMain,
  children: [
	
	
	{
	  path: 'index',
	  name: `${pre}index`,
	  meta: {
	    auth: ['order'],
	    title: '订单',
	  },
	  component: () => import('@/pages/order/index'),
	},
	
	
  ],
};
